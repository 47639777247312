import React, { useCallback, useEffect, useState, useRef } from "react";
import * as classes from "../Academyhome.module.scss"
import Carousel from "react-multi-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import reelone from "../../../assets/images/Ajeeshreel.mp4"
import reeltwo from "../../../assets/images/reeltwo.mp4"


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Tourismcarousel = () => {

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRefs = [useRef(null), useRef(null)];

  const handleSlideChange = (currentSlide) => {
    if (currentSlide !== currentVideoIndex) {
      
      const currentlyPlayingVideo = videoRefs[currentVideoIndex].current;
      if (currentlyPlayingVideo) {
        currentlyPlayingVideo.pause();
      }
     
      setCurrentVideoIndex(currentSlide);
    }
  };

    return (

        <div>
            <Carousel
                className={classes.carousel}
                // autoPlay
                // autoPlaySpeed={5000}
                afterChange={handleSlideChange}
                arrows={true}
                infinite={false}
                responsive={responsive}
            /* className="container" */
            >

                <div className={classes.fifthblock}>
                    <div className={classes.fifthleft}
                    selectedItem={currentVideoIndex} 
                    onChange={handleSlideChange}
                    >
                        <h2>Testimonials</h2>
                        <p>Dare to be the first one to take a step forward, to make a decision when the world is sceptical.
                            There lies your freedom!
                            The story is of one such "Torch Bearer"</p>
                    </div>
                    <div className={classes.fifthright}>
                        <video src={reelone} controls="controls" type="video/mp4" ref={videoRefs[0]} />
                    </div>
                </div>

                <div className={classes.fifthblock}
                selectedItem={currentVideoIndex} 
                onChange={handleSlideChange}
                >
                    <div className={classes.fifthleft}>
                        <h2>Testimonials</h2>
                        <p> I had an urge to go on a solo trek.I came to know about the personal buddy/guide concept of Able Aura in the Year 2022 where I could get the opportunity to meet people not only from his own disability community but from different communities who might have different disabilities and also because I wanted to know what is the possibility of actually climbing the Second Highest Peak in Tamil Nadu.</p>
                    </div>
                    <div className={classes.fifthright}>
                        <video src={reeltwo} controls="controls" type="video/mp4" ref={videoRefs[1]} />
                    </div>
                </div>
              
            </Carousel>
        </div>


    )

}


export default Tourismcarousel;