import React, { useCallback, useEffect, useState } from "react";
import * as classes from "./tourism.module.scss";
import { navigate } from "gatsby";
import { getLocalstoreData } from "../../../Store/GetData";
import Mysore from "../../../assets/images/mysore.jpg"
import { Carousel, Col, Row, Spinner } from "react-bootstrap";
import Parentscarousel from "../Parentscarousel";
import T1 from "../../../assets/images/InclusiveExploration.png"
import T2 from "../../../assets/images/Personal.png"
import T3 from "../../../assets/images/UnforgettableMemories.png"
import T4 from "../../../assets/images/rethink.png"
import T5 from "../../../assets/images/Cultural.png"
import T6 from "../../../assets/images/TravelCompanion.png"
import T7 from "../../../assets/images/Barrier.png"
import T8 from "../../../assets/images/Redefined.png"
import Tourismcarousel from "./Tourismcarousel";
import Seo from "../../Seo";
import { successtoast } from "../../../services/utility";
import { SuccessModalShow } from "../../../Store/Action/Action";
import { useDispatch } from "react-redux";
import MPM from "../../../assets/images/MTour.jpg"
import TourismFaq from "./Tourismfaq";
import Gallery from "../Gallery";
import Kodai from "../../../assets/images/kodai.jpg"
import Videoplayer from "../Videoplayer";
import TVideoplayer from "../../Elements/Tourismvideo";
import SingleIcons from "../../Elements/Singleicons";
import TourismGallery from "./TourismGallery";
import Link from "../../../services/utility";
import Succesicon from "../../../assets/images/success.png"
import { Helmet } from "react-helmet";



const Tourismhome = () => {
    let dispatch = useDispatch()
    const [isLoading, setisLoading] = React.useState(true);
    React.useEffect(() => {
        setisLoading(false)
    }, [])
    const [SEOData, setSEOData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const scrollToTourSection = () => {
        const tourSection = document.getElementById('scrolltoTour');
        if (tourSection) {
            tourSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const iconsBlock = [
        {
            src: T1,
            text: "  Free your inner explorer",

        },
        {
            src: T2,
            text: "Become a leader",

        },
        {
            src: T3,
            text: "Create lasting memories",

        },
        {
            src: T4,
            text: "Redefine accessibility",

        },
        {
            src: T5,
            text: "Immerse in cultures",

        },
        {
            src: T8,
            text: "Enable living scenarios",

        },

    ]

    const scrollToResult = () => {
        const resultContainer = document.getElementById('booking');
        if (resultContainer) {
            resultContainer.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        scrollToResult();
    };

    useEffect(() => {
        const handleScroll = () => {
            const threshold = 500;
            if (window.scrollY > threshold) {
                setShowAlert(true);
            } else {
                setShowAlert(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const locationData = getLocalstoreData("lxct");
    // console.log(locationData, "LLLLLLLLLLL");
    var location;


    if (locationData) {
        if (locationData === "ablectn01") {
            location = "chennai";
        }
        if (locationData === "ablekba02") {
            location = "bangalore";
        }
        // console.log(location, "iiiiiiiiiiiiii")
    }




    return isLoading ? (
        <>
            <div className="clearfix" />
            <div className="mainsec">
                <div className="loadingSec">
                    <Spinner animation="border" variant="dark" />
                </div>
            </div>
        </>
    ) : (
        <>
            <Helmet>
                <title>Travel the world with confidence: The magic of Accessible Tourism</title>
                <meta
                    name="description"
                    content="Go on exciting treks & hikes, explore sights, experience cultures and create unforgettable memories with personalised accessible tourism & travel for disabled adults."
                />
                <meta property="og:title" content="Travel the world with confidence: The magic of Accessible Tourism" />
                <meta
                    property="og:description"
                    content="Go on exciting treks & hikes, explore sights, experience cultures and create unforgettable memories with personalised accessible tourism & travel for disabled adults."
                />
                <meta property="og:image" content="../../../assets/images/ETS.jpg" />
                <meta property="og:url" content="https://dev.academy.ableaura.com/tourism" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Seo
                title={"Travel the world with confidence: The magic of Accessible Tourism "}
                description={`Go on exciting treks & hikes, explore sights, experience cultures and create unforgettable memories with personalised accessible tourism & travel for disability adults,
                Become empowered through accessibility in travel & tourism: persons with disabilities can now enjoy rich and adventurous traveling experiences.`}
                keywords="tourism for all disabled travellers,
                accessibility tourism,
                accessibility in travel and tourism,
                accessible travel,
                disabled travel,
                disabled accessible travel,
                travel for people with disabilities,
                accessibility travel,
                special needs travel,
                travel accessibility,
                luxury travel for disabled,
                disabled travel companies,
                disability travel groups,
                travel for disabled adults,
                tours for disabled,
                travel tours for disabled,
                tours for disabled people,
                best trips for disabled,
                trips for special needs adults,
                best holiday for disabled,
                special needs vacations,
                best vacations for disabled seniors,
                disabled vacation packages,
                best holiday destinations for disabled,
                accessibility in tourism industry,
                accessibility of tourism destination,
                accessible travel agency,
                disabled travel tours,
                tours for disabled seniors,
                day trips for disabled adults,
                disabled travel agency,
                tour for disabled person,
                disabled travel tours,
                tours for disabled seniors,
                tours for disabled,
                meesapulimala tour in able aura,
                farm vist in able aura,
                wayanad tour in able aura,
                yelagiri tour in able aura,"
            // image={SEOData?.og_image?.data?.url}
            />


            <div className="clearfix" />
            {showAlert && (
                <div className={classes.alertinfo}>
                    <p onClick={() => {
                        navigate('/success-stories')
                    }}>
                        <img style={{ width: "30px", height: "30px" }} src={Succesicon} />
                        Explore Our Inspiring Success Stories
                    </p>
                </div>
            )}
            <TVideoplayer />

            <div className="clearfix" />

            <div className="mainsec">
                <h3 className={classes.headingtwo}>EXPERIENCES CAN TRANSFORM THE WAY YOU ENGAGE WITH THE WORLD </h3>
                <h2 className={classes.headingthree}>
                    Accessible tourism transcends mere abstraction; it's a vibrant system of inclusive adventure tours that can redefine communal perspectives and individual interactions with the world. At Tribe Able Aura, we believe everyone deserves the chance to trek, hike, and explore breathtaking landscapes. Our dynamic adventure tours empower persons with disabilities to become seasoned travelers and leaders, breaking barriers one excursion at a time.
                </h2>
                {/* <h2 className={classes.headingthree}>When we speak of "accessible tourism", we aren't talking about a concept or an idea, but a dynamic system of assisted adventure tours that can potentially shape perspectives shared within a community, and even change the way individuals engage with the world around them.
                </h2>
                <h2 className={classes.headingthree}>There's no reason why persons with disabilities should have to miss out on opportunities to go on trek & hikes, explore wondrous landscapes, and become seasoned travellers & leaders. Tribe Able Aura is dedicated to making this dream come alive through our exciting adventure tours for persons with disabilities.
                </h2> */}


                {/* <button
                    onClick={handleButtonClick}
                    style={{ fontWeight: "600", display: "block", margin: "auto" }}
                    className={classes.btnout}
                >
                    <div className={classes.btnin}>TAKE A TRIP</div>

                </button> */}
            </div>
            <SingleIcons header="A Window into the Future of Accessible Tourism" iconsBlock={iconsBlock} />


            <div className="clearfix" />
            <div className="mainsec">


                {/* <Tourismcarousel /> */}


                <div className={classes.RightclubSec}>
                    <div className={classes.headingtext}>

                    </div>
                    <div className={classes.storytextcontent}>
                        <h3 style={{ textAlign: "left" }}>Inclusive Adventures</h3>
                        <p>Our travel experiences are meticulously curated to cater to a wide range of abilities, ensuring that every participant feels valued, comfortable, and empowered to explore.</p>
                    </div>
                    <div className={classes.storytextcontent}>
                        <h3 style={{ textAlign: "left" }}>Personalised Experiences</h3>
                        <p>We understand that every traveler is unique. Our itineraries are customized to accommodate individual preferences and requirements, allowing for a truly personal and enjoyable journey.</p>
                    </div>
                    <div className={classes.storytextcontent}>
                        <h3 style={{ textAlign: "left" }}>Immaculate Organisation</h3>
                        <p>Our team comprises accessibility experts who ensure that all aspects of the trip, from accommodations to transportation and attractions, adhere to the highest quality standards.</p>
                    </div>
                    <div className={classes.storytextcontent}>
                        <h3 style={{ textAlign: "left" }}>Comprehensive Support</h3>
                        <p>From travel planning to on-the-ground assistance, our dedicated team is there every step of the way to provide guidance, support, and peace of mind.</p>
                    </div>
                </div>


                <div className={classes.tour} id="scrolltoTour">
                    {/* <div className={classes.tourdetails}>
                        <img src={Kodai} />
                        <div className={classes.subcontainer}>
                            <p> <b>Kodaikanal</b> <br></br>
                                Your travel journey starts here
                            </p>
                            <button
                                onClick={() => {
                                    window.open("https://forms.zohopublic.in/ableaura/form/KodaikanalTravellerdetails/formperma/iEnMGxwb0fknwTTElRwq9mOqwSccimNHWauLq_JVpBI", '_blank')
                                }}
                               
                                aria-label="Book Now for kodaikanal tour"
                                className={classes.btnout}>
                                <div className={classes.btnin} style={{ fontSize: "16px" }}>BOOK NOW</div>
                            </button>
                        </div>
                    </div>

                    <div className={classes.tourdetails}>
                        <img src={Mysore} />
                        <div className={classes.subcontainer}>
                            <p> <b>Mysore</b> <br></br>
                                Your travel journey starts here
                            </p>
                            <button
                              
                                onClick={() => {
                                    dispatch(
                                        SuccessModalShow({
                                            show: true,
                                            message: "Contact Santhosh for Booking - 9655311116",
                                        })
                                    );
                                }}
                                aria-label="Book now for mysore tour"
                                className={classes.btnout}>
                                <div className={classes.btnin} style={{ fontSize: "16px" }}>BOOK NOW</div>
                            </button>
                        </div>
                    </div> */}


                </div>

                <div className="clearfix" />
                <h3 style={{ textAlign: "left", paddingBottom: "0px", marginBottom: "40px" }}> A Glimpse of Our Previously Explored Destinations.</h3>
                <TourismGallery />


                {/* <div className="clearfix" />
                <div className={classes.tourdetails} id="booking">
                    <img src={MPM} alt="meesapulimala tour able aura" />
                    <div className={classes.subcontainer}>
                        <p style={{ marginBottom: "30px" }}> <b>Meesapulimala</b> <br></br>
                            Get Started On Your Journey Amidst The Clouds
                        </p>
                       
                        <button
                            style={{ marginBottom: "14px" }}
                            onClick={() => {
                                if (location === "bangalore") {
                                    window.open("https://able-email-media.s3.amazonaws.com/academy/Meeshapulimala+Itinerary+Banglore.pdf");
                                } else {
                                    window.open("https://able-email-media.s3.amazonaws.com/Meeshapulimala.pdf", "_blank");
                                }
                            }}
                            aria-label="Book now for Meesapuliumala tour"
                            className={classes.btnout}
                        >
                            <div className={classes.btnin} style={{ fontSize: "16px" }}>VIEW ITINERARY</div>
                        </button>

                        <button
                            style={{ marginBottom: "14px" }}
                            onClick={() => {
                                window.open("https://docs.google.com/forms/d/e/1FAIpQLSeKqXeLo2ckWNBb1sSfiQE16Tsqqmuxyu_QzbMemhbqye3xdw/viewform", "_blank");
                            }}
                            aria-label="Book now for Meesapuliumala tour"
                            className={classes.btnout}>
                            <div className={classes.btnin} style={{ fontSize: "16px" }}>ENQUIRE</div>
                        </button>
                    </div>
                </div> */}



                <div className={classes.faq}>
                    <TourismFaq />
                </div>


            </div>
        </>
    )


}


export default Tourismhome;