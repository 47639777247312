import React from 'react'
import Tourismhome from '../components/Sportability/Tourism/Tourismhome'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

function Tourism() {
    return (
        <>
            <Header />
            <Tourismhome />
            <Footer />
        </>
    )
}

export default Tourism
