import * as React from "react";
import { Spinner } from "react-bootstrap";


const NotFoundPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Loading complete");
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      <div className="clearfix" />
      <div className="mainsec">
        {isLoading ? (
          <div className="loadingSec">
            <Spinner animation="border" variant="dark" />{" "}
          </div>
        ) : (
          <div className="loadingSec">
            <p>Page Not Found !</p>
          </div>
        )}
      </div>
    </>
  );
};

export default NotFoundPage;


