exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-sports-js": () => import("./../../../src/pages/academy/sports.js" /* webpackChunkName: "component---src-pages-academy-sports-js" */),
  "component---src-pages-academy-sportsheading-js": () => import("./../../../src/pages/academy/sportsheading.js" /* webpackChunkName: "component---src-pages-academy-sportsheading-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tourism-js": () => import("./../../../src/pages/tourism.js" /* webpackChunkName: "component---src-pages-tourism-js" */)
}

