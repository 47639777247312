import React, { useCallback, useEffect, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as classes from "../../Acedamycomponets/Login.module.scss";
import { FormControl } from "react-bootstrap";

const TourismFaq = () => {
    const [isLoadMore, setisLoadMore] = useState(true);
    const [isLoadLess, setisLoadLess] = useState(false);
    const [FaqTotalLength, setFaqTotalLength] = useState();
    const [FaqData, setFaqData] = useState([]);
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredFaqData, setFilteredFaqData] = useState([]);
    const [originalDataLength, setOriginalDataLength] = useState(0);

    const manualFaqData = [
        {
            question: "What is Able Aura Tourism all about?",
            answer: "Able Aura Tourism is a specialized travel agency dedicated to providing accessible tours for individuals with disabilities. We design inclusive itineraries that cater to various accessibility needs, allowing everyone to experience the joy of travel."
        },
        {
            question: "What types of accessible tours do you offer?",
            answer: "We offer a wide range of accessible tours, including cultural, adventure, wildlife, and leisure trips. Our itineraries are carefully crafted to ensure inclusivity and comfort for all travelers."
        },
        {
            question: "How can I register for one of your accessible tours?",
            answer: "To register for a tour, visit our website and fill out the registration form. You can choose from available itineraries and provide all the necessary details to secure your spot."
        },
        {
            question: "Can I read testimonials from travelers who have taken your tours?",
            answer: "Yes, you can! Our Testimonials section on the website features feedback and experiences shared by our past travelers, giving you insights into the quality of our services."
        },
        {
            question: "Is there a gallery showcasing the destinations and experiences on your tours?",
            answer: "Certainly! Our Gallery is filled with captivating photos and videos from our previous trips. Explore the stunning destinations and experiences we offer."
        },
        // {
        //     question: "Do you have information about upcoming trips and itineraries?",
        //     answer: "Yes, you can find details about our upcoming trips and itineraries on the 'Future Trips' page of our website. We keep this section regularly updated."
            
        // },
        {
            question: " What makes Able Aura Tourism stand out for travelers with disabilities?",
            answer: "We prioritize accessibility and inclusivity in all aspects of our tours. Our experienced team and accessible accommodations ensure that every traveler's needs are met, allowing them to explore the world with confidence."
        },
        {
            question: "Are there options for travelers with different types of disabilities?",
            answer: "Yes, we provide accommodations and support for a wide range of disabilities, including mobility challenges, visual or hearing impairments, and more. We tailor our services to meet individual needs."
        },
        {
            question: "Can I request specific accommodations or accessibility features for my tour?",
            answer: "Absolutely! We encourage travelers to communicate their specific requirements during the registration process. We'll do our best to accommodate your needs."
        },
        {
            question: "Are there group tours and private tour options available?",
            answer: "Yes, we offer both group tours and private tour options to cater to different preferences. You can choose the one that best suits your travel style."
        },
        {
            question: "Is financial assistance or discounts available for travelers with disabilities?",
            answer: "We offer special pricing and discounts for travelers with disabilities. Please contact our team to inquire about available discounts and eligibility criteria."
        },
        {
            question: "How can I contact Able Aura Tourism for further inquiries or assistance?",
            answer: "You can reach out to us through the 'Contact Us' section on our website. We're here to assist you with any questions, concerns, or special requests."
        },
    ];

    const getFaq = useCallback((value) => {
        if (value) {
            setTimeout(() => {
                
                const data = manualFaqData;
    
                setFaqTotalLength(data.length);
                setFaqData(data);
                setFilteredFaqData(data.slice(0, 6));
                setOriginalDataLength(data.length);
                setisLoadMore(data.length > 6);
                setisLoadLess(false);
            }, 1000);
        }
    }, []);
    useEffect(() => {
        getFaq(true);
    }, []);

    const handleLoadMore = () => {
        const newData = FaqData.slice(0, filteredFaqData.length + 6); // Increase the displayed items by 6
        setFilteredFaqData(newData);
        setisLoadMore(newData.length < FaqData.length); // Check if there are more items to load
        setisLoadLess(true);
    };

    const handleLoadLess = () => {
        setFilteredFaqData(FaqData.slice(0, 6)); // Show the initial 6 items
        setisLoadMore(FaqData.length > 6);
        setisLoadLess(false);
    };
    /*   const filteredFaqData = FaqData?.filter((item) =>
          item.question.toLowerCase().includes(searchQuery.toLowerCase())
      ); */

    useEffect(() => {
        if (searchQuery !== '') {
            // Filter the FAQ data based on the search query
            const filteredData = FaqData.filter(
                (item) =>
                    item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.answer.toLowerCase().includes(searchQuery.toLowerCase())
            );

            setFilteredFaqData(filteredData);
            setisLoadMore(filteredData.length > 6);
            setisLoadLess(false);
        } else {
            // Show initial 6 items if there is no search query
            setFilteredFaqData(FaqData.slice(0, 6));
            setisLoadMore(FaqData.length > 6);
            setisLoadLess(false);
        }
    }, [searchQuery, FaqData]);


    return (
        <div className="mainsec">
        
                <h1 aria-level={2} style={{textAlign:"center",paddingBottom:"20px",paddingTop:"30px"}}>FAQs</h1>
           

            <div className={classes.faqQuestion}>
                <FormControl
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search for answers..."
                />
                {filteredFaqData.length > 0 ? (
                    <Accordion>
                        {filteredFaqData.map((item, index) => (
                            <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header> {item.question}</Accordion.Header>
                                <Accordion.Body>{item.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                ) : (
                    <div className={classes.noResultsMessage}>
                        <p>No results found.</p>
                    </div>
                )}


            </div>

            {searchQuery === '' && filteredFaqData.length > 6 && isLoadMore && (
                <div className={classes.loadMore}>
                    <button className={classes.loadSec} onClick={handleLoadMore}>
                        <i className={classes.loadIcon}></i> <p>LOAD MORE</p>
                    </button>
                </div>
            )}

            {searchQuery === '' && filteredFaqData.length > 6 && isLoadLess && (
                <div className={classes.loadMore}>
                    <button className={classes.loadSec} onClick={handleLoadLess}>
                        <i className={classes.loadLess}></i> <p>LOAD LESS</p>
                    </button>
                </div>
            )}

        </div>
    );
};

export default TourismFaq;
