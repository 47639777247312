import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import * as classes from '../Gallery.module.scss';
import { navigate } from "gatsby";
import Seo from "../../Seo";
import { Galleryfile } from "../../../services/SEOServices"
import { useDispatch } from "react-redux";
import { FailureModalShow } from "../../../Store/Action/Action";
import { Modal } from "react-bootstrap";
import Nextarrow from "../../../assets/images/next.png"
import Prevarrow from "../../../assets/images/prevarrow.png"
//import { FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';





const TourismGallery = () => {
    const [SEOData, setSEOData] = useState({});
    const [isLoading, setisLoading] = React.useState(true);
    const [messageModal, setmessageModal] = useState({
        show: false,
    });
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const [imagefile, setimagefile] = useState([]);
    const dispatch = useDispatch();

    const handlegallery = () => {
        Galleryfile()
            .then((response) => {
                // console.log('API Response:', response.data);
                const imageData = response.data.data;
                setimagefile(imageData);
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    React.useEffect(() => {
        handlegallery();
    }, [])




    React.useEffect(() => {
        setisLoading(false);
    }, [])

    const handleClick = (galleryItem, index) => {
        setSelectedImage(galleryItem);
        setSelectedImageIndex(0);
        setModalShow(true);
    };


    const handlePrev = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : selectedImage.inner_images.data.length - 1
        );
    };



    const handleNext = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex < selectedImage.inner_images.data.length - 1 ? prevIndex + 1 : 0
        );
    };
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.keyCode) {
                case 37: // Left arrow key
                    handlePrev();
                    break;
                case 39: // Right arrow key
                    handleNext();
                    break;
                default:
                    // Do nothing for other keys
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            // Cleanup the event listener when the component unmounts
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handlePrev, handleNext]);



    const handleTouchStart = (event) => {
        setTouchStartX(event.touches[0].clientX);
    };
    const handleTouchMove = (event) => {
        setTouchEndX(event.touches[0].clientX);
    }

    const handleTouchEnd = () => {
        const distance = touchEndX - touchStartX;

        if (distance > 0) {
            handlePrev();
        } else {
            handleNext();
        }
    };


    return isLoading ? (
        <>
            <div className="clearfix" />
            <div className="mainsec">
                <div className="loadingSec">
                    <Spinner animation="border" variant="dark" />
                </div>
            </div>
        </>
    ) : (

        <>

            <Seo
                title={"Travel the world with confidence: The magic of Accessible Tourism "}
                description={`Go on exciting treks & hikes, explore sights, experience cultures and create unforgettable memories with personalised accessible tourism & travel for disability adults,
                Become empowered through accessibility in travel & tourism: persons with disabilities can now enjoy rich and adventurous traveling experiences.`}
                keywords="tourism for all disabled travellers,
                accessibility tourism,
                accessibility in travel and tourism,
                accessible travel,
                disabled travel,
                disabled accessible travel,
                travel for people with disabilities,
                accessibility travel,
                special needs travel,
                travel accessibility,
                luxury travel for disabled,
                disabled travel companies,
                disability travel groups,
                travel for disabled adults,
                tours for disabled,
                travel tours for disabled,
                tours for disabled people,
                best trips for disabled,
                trips for special needs adults,
                best holiday for disabled,
                special needs vacations,
                best vacations for disabled seniors,
                disabled vacation packages,
                best holiday destinations for disabled,
                accessibility in tourism industry,
                accessibility of tourism destination,
                accessible travel agency,
                disabled travel tours,
                tours for disabled seniors,
                day trips for disabled adults,
                disabled travel agency,
                tour for disabled person,
                disabled travel tours,
                tours for disabled seniors,
                tours for disabled,
                meesapulimala tour in able aura,
                farm vist in able aura,
                wayanad tour in able aura,
                yelagiri tour in able aura,"
            />
          

           

                <div className="clearfix"></div>
                {imagefile ? (
                    <div>


                        {/* <p className={classes.mob}>Explore our academy,tourism images here</p> */}


                        <div className={classes.topcontainer}>
                            {imagefile.gallery_block &&
                                imagefile.gallery_block
                                    .filter(galleryItem => galleryItem.id !== 1 && galleryItem.id !== 5) // Filter out items with ids 1 and 4
                                    .map((galleryItem, index) => (
                                        <div key={galleryItem.id} className={`${classes.container} ${index % 5 === 0 ? classes.big : ''}`}>
                                            {galleryItem.inner_images && galleryItem.inner_images.data && galleryItem.inner_images.data.length > 0 ? (
                                                <img
                                                    src={galleryItem.inner_images.data[0]?.formats?.large?.url}
                                                    alt={galleryItem.inner_images.data[0]?.alternativeText}
                                                />
                                            ) : (
                                                <p>No images available</p>
                                            )}
                                            <p>{galleryItem.normal_title}</p>
                                            <div className={classes.overlay} onClick={() => handleClick(galleryItem, index)}>
                                                <div className={classes.text}>{galleryItem.hover_title}</div>
                                            </div>
                                        </div>
                                    ))}
                        </div>



                        {selectedImage && (
                            <Modal
                                fullscreen={true}
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                size="xl"
                                centered
                                className={classes.custommodal}
                            //className={classes.modal}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>{selectedImage?.hover_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className={classes.modalpara} >{selectedImage?.description}</p>
                                    <div className={classes.modalarrows}>
                                        <button className={classes.arrowbutton} onClick={handlePrev}>
                                            <img className="onlyDes" src={Prevarrow} />
                                        </button>
                                        <div className={classes.modalcontent}>
                                            {selectedImage.inner_images &&
                                                selectedImage.inner_images.data &&
                                                selectedImage.inner_images.data.length > 0 ? (

                                                <img
                                                    src={selectedImage.inner_images.data[selectedImageIndex]?.formats?.large?.url}
                                                    alt={selectedImage.inner_images.data[selectedImageIndex]?.alternativeText}
                                                    onTouchStart={handleTouchStart}
                                                    onTouchMove={handleTouchMove}
                                                    onTouchEnd={handleTouchEnd}
                                                />
                                            ) : (
                                                <p>No images available</p>
                                            )}
                                        </div>
                                        <button className={classes.arrowbutton} onClick={handleNext}>
                                            <img className="onlyDes" src={Nextarrow} />
                                        </button>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>

                                </Modal.Footer>
                            </Modal>
                        )}





                    </div >
                ) : (
                    <p>Images are Loading...</p>
                )}
            
        </>
    )
}


export default TourismGallery;
