const React = require("react");
require("./src/pages/common.scss");
const { Provider } = require("react-redux");
const { rootReducer } = require("./src/Store/Reducer/Reducer");
const { createStore } = require("redux");
const { getCookieData } = require("./src/Store/GetData");
const { navigate } = require("gatsby");
const { Sessionstore } = require("./src/Store/StoreData");

const store = createStore(rootReducer);

exports.wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
/* global Cal */
exports.onClientEntry = () => {
  if (typeof window !== 'undefined') {
    (function (C, A, L) {
      let p = function (a, ar) { a.q.push(ar); };
      let d = C.document;
      C.Cal = C.Cal || function () {
        let cal = C.Cal;
        let ar = arguments;
        if (!cal.loaded) {
          cal.ns = {};
          cal.q = cal.q || [];
          d.head.appendChild(d.createElement("script")).src = A;
          cal.loaded = true;
        }
        if (ar[0] === L) {
          const api = function () { p(api, arguments); };
          const namespace = ar[1];
          api.q = api.q || [];
          typeof namespace === "string"
            ? (cal.ns[namespace] = api) && p(api, ar)
            : p(cal, ar);
          return;
        }
        p(cal, ar);
      };
    })(window, "https://bookings.academy.ableaura.com/embed/embed.js", "init");
  }
};
exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  const scrollToTopRoutes = [
    `/ourstory/advisor`,
    `/ourstory/enabler`,
    `/ourstory/doers`,
  ];
  if (!scrollToTopRoutes.includes(pathname)) {
    window.scrollTo(0, 0);
  }
  return false;
};

// const HeadComponents = [
//   <meta charSet="utf-8" />,
//   <meta
//     name="viewport"
//     content="width=device-width, initial-scale=1, maximum-scale=3, user-scalable=yes"
//   />,
//   <title>Ableaura</title>,
//   <meta
//     name="og:url"
//     content="https://creations.ableaura.com/"
//     data-react-helmet="true"
//   />,
//   <meta
//     name="description"
//     content="India's first PWD Ecosystem Ableaura"
//     data-react-helmet="true"
//   />,
//   <meta property="og:site_name" content={'Ableaura'} />,
//   <meta property="og:type" content="website" data-react-helmet="true" />,
//   <meta property="og:title" content="Ableaura" data-react-helmet="true" />,
//   <meta
//     property="og:description"
//     content="India's first PWD Ecosystem Ableaura"
//     data-react-helmet="true"
//   />,
//   <meta
//     property="og:image"
//     itemProp="image"
//     content="%PUBLIC_URL%/Olympic.png"
//     data-react-helmet="true"
//   />,
//   <meta property="og:image:width" content="299" />,
//   <meta property="og:image:height" content="200" />,
//   <meta property="og:type" content="website" />,
//   <meta property="og:updated_time" content="1440432930" />,
//   <meta
//     name="twitter:card"
//     content="summary_large_image"
//     data-react-helmet="true"
//   />,
//   <meta name="twitter:title" content="Ableaura" data-react-helmet="true" />,
//   <meta
//     name="twitter:description"
//     content="India's first PWD Ecosystem Ableaura"
//     data-react-helmet="true"
//   />,
//   <meta
//     name="twitter:image"
//     content="%PUBLIC_URL%/defaultImg.png"
//     data-react-helmet="true"
//   />,
// ]

// exports.onRenderBody = ({ setHtmlAttributes, setHeadComponents }) => {
//   // setHeadComponents(HeadComponents)
// }
