import React, { useCallback, useEffect, useState } from "react";
import * as classes from "../Sportability/sportable.module.scss";
import ReactPlayer from "react-player/youtube";

import Carousel from "react-multi-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2.9,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2.9,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};


const TVideoplayer = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const getVideoSource = () => {
        switch (activeTab) {
            case 1:
                return 'https://youtu.be/lYvG7DXsIM4';

            default:
                return '';
        }
    };



    return (
        <>
            <div className="clearfix" ></div>

            <div className={classes.videocontainer}>
                <div className={classes.fourthblock}>

                    <div className={classes.player}>
                        <ReactPlayer url="https://youtu.be/-nErlmmzY4I?si=I17J37GomKUKcHZY" controls={false} playing={false} class="onlyDes" width="100%" height="550px"
                       config={{
                        youtube: {
                          playerVars: {
                          
                            fs: 0, // disable fullscreen button
                            controls: 0, // hide player controls
                          },
                        },
                      }}
                            frameborder="0" />
                        <ReactPlayer url="https://youtu.be/-nErlmmzY4I?si=I17J37GomKUKcHZY" controls={true} playing={false} class="onlyMob" width="100%" height="100%" />
                    </div>
                </div>
                {/* <div className={classes.videolist}>
                    <Carousel
                    infinite={true}
                        arrows={false}
                        //infinite={true}
                        responsive={responsive}
                    >
                        <div className={classes.imgcontainer}>
                            <img
                                src={Slider2}
                              
                                onClick={() => handleTabClick(1)}
                            />
                            <p>Sports Academy Launch</p>
                        </div>
                    </Carousel>
                </div> */}
            </div>
        </>

    )


}

export default TVideoplayer;
